/**
 *  项目的配置文件
 */

export const apiUrl = 'https://seller.ti-dao.com/'//接口请求地址
// export const apiUrl = 'http://127.0.0.1:8001/'//接口请求地址
export const chatUrl = 'wss://im.ti-dao.com'//客服地址
export const storeUrl = 'https://seller.ti-dao.com/';//对应的商户后台地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v3.4 *** date-2022-03-01 ***主版本v3.8**/
